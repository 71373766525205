import { Box, Stack } from "@mui/material";
import { parlorRegion } from "@/constants/parlorRegion";
import {
  BTypeCardView,
  ColorIcon,
  colors,
  getCdnImageSrc,
  FitWidthSmallButton,
  GOI_WEB_URL,
  call,
  Link,
} from "goi_common";

import parlors from "goi_common/src/constants/parlors_detail.json";

const { targetSidos, regionColor } = parlorRegion;

export default function Home() {
  const regionParlors = parlors.filter((p) => targetSidos.includes(p.sido));
  return (
    <Stack style={{ background: "white" }}>
      <Stack
        className="caption_12_sb"
        padding="0 16px"
        flexDirection="row"
        gap="16px"
        marginBottom="1px"
      >
        <Box padding="8px 0" borderBottom="3px solid black">
          장례식장 정보
        </Box>
        <Link
          className="gray600"
          style={{ textDecoration: "none", padding: "8px 0" }}
          href="/gangwon-benefit/"
        >
          기초생활수급자
        </Link>
        <Link
          className="gray600"
          style={{ textDecoration: "none", padding: "8px 0" }}
          href={GOI_WEB_URL + "/service/flower/"}
        >
          화환 보내기
        </Link>
      </Stack>
      <Stack
        height="400px"
        padding="48px 20px"
        style={{
          background: `url(${getCdnImageSrc("dogsound/gangwon/homebackground.png")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Stack gap="20px">
          <Box>
            <Box className="subtitle_14_sb gray700">장례가 필요할 때</Box>
            <Box
              fontFamily="GmarketSans"
              fontSize="18px"
              fontWeight={700}
              className="white"
              component="h2"
            >
              강원장례정보공개포털
              <span className="orange650">.</span>
            </Box>
          </Box>
          <Stack>
            <FitWidthSmallButton
              text="장례 견적 알아보기"
              backgroundColor={regionColor.c400}
              href={GOI_WEB_URL + "/match/request/"}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack flexDirection="row" justifyContent="center" gap="44px" padding="24px 0 60px">
        <Stack
          alignItems="center"
          onClick={() => {
            call("1666-9784");
          }}
        >
          <ColorIcon name="symbol-call" size={56} color="black" />
          <Box className="subtitle_14_sb">긴급 장례 상담</Box>
        </Stack>
        <Box height="65px" width="1px" style={{ background: colors.gray500 }} />
        <Link
          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          href={GOI_WEB_URL + "/service/flower/"}
        >
          <ColorIcon name="flower-line" size={56} color="black" />
          <Box className="subtitle_14_sb black" style={{ textDecoration: "none" }}>
            화환 2시간 배송
          </Box>
        </Link>
      </Stack>
      <Stack className="subtitle_16_b gray900" m="6px 20px">
        <h3>강원 장례식장 추천 목록 </h3>
      </Stack>
      <Stack padding="0 20px" gap="10px">
        {regionParlors.map((res) => (
          <BTypeCardView
            descriptions={[`빈소 이용료: ${res.binsos[0].charge.toLocaleString()}원부터`]}
            mainColor={regionColor}
            key={res.companyname}
            name={res.companyname}
            href={`/gangwon-parlor/${res.companyname} 정보/`}
            imgSrc={res.images[0]?.url ? res.images[0].url : ""}
          />
        ))}
      </Stack>
    </Stack>
  );
}
